import { useEffect, useState } from 'react'

import { useUpdateStoreAutoCloseQuotesMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import Checkbox from '@/gf/components/Checkbox'
import Field from '@/gf/components/Field'
import Select from '@/gf/components/Select'
import TextInput from '@/gf/components/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { H2 } from '../WorkspaceSettings'

export default function AutoCloseQuotes() {
  const {
    store: { autoCloseQuotes },
    storeId,
  } = useSession()
  const reloadSession = useReloadSession()
  const [_msgs, msgsMgr] = useMsgs()
  const [updateStoreAutoCloseQuotes, { loading }] = useUpdateStoreAutoCloseQuotesMutation({
    onCompleted: () => {
      msgsMgr.add('"Auto close quotes" settings updated', 'positive')
      reloadSession()
    },
    onError: (error) => {
      msgsMgr.add(error?.message || 'Error updating "Auto close quotes" settings', 'negative')
    },
  })

  const defaultValues = {
    enabled: autoCloseQuotes?.enabled ?? true,
    frequencyValue: autoCloseQuotes?.frequencyValue ?? 6,
    frequencyUnit: autoCloseQuotes?.frequencyUnit ?? 'months',
  }

  const [enabled, setEnabled] = useState(() => defaultValues.enabled)
  const [frequencyValue, setFrequencyValue] = useState(() => defaultValues.frequencyValue)
  const [frequencyUnit, setFrequencyUnit] = useState(() => defaultValues.frequencyUnit)

  const [editing, setEditing] = useState(false)

  function setDefaultValues() {
    setEnabled(autoCloseQuotes?.enabled ?? true)
    setFrequencyValue(autoCloseQuotes?.frequencyValue ?? 6)
    setFrequencyUnit(autoCloseQuotes?.frequencyUnit ?? 'months')
    setEditing(false)
  }

  useEffect(() => {
    if (
      enabled !== defaultValues.enabled ||
      frequencyValue !== defaultValues.frequencyValue ||
      frequencyUnit !== defaultValues.frequencyUnit
    ) {
      return setEditing(true)
    }

    setEditing(false)
  }, [enabled, frequencyValue, frequencyUnit, defaultValues, autoCloseQuotes])

  return (
    <div className="px-8 py-6 last:pb-0">
      <H2 id="close-stale-quotes">Automatically Close Stale Quotes</H2>
      <p className="text-sm mt-6">
        By default, quotes that stay inactive for over six months are automatically closed.
      </p>
      <p className="text-sm">
        You can enable or disable this feature at any time and adjust how frequently it runs.
      </p>

      <div className="flex flex-col gap-3 mt-6 max-w-sm">
        <Checkbox
          label="Enabled"
          className="w-max"
          checked={enabled}
          onChange={() => setEnabled((prev) => !prev)}
        />
        <Field label="Every">
          <TextInput
            value={frequencyValue.toString()}
            onChange={(e) => setFrequencyValue(parseInt(e.target.value || '1'))}
            type="number"
            step="1"
            max={99}
            min={1}
            leftIconPad
          />
        </Field>
        <Select
          options={[
            { id: 'months', display: 'Months' },
            { id: 'years', display: 'Years' },
          ]}
          currentId={frequencyUnit}
          onChange={(id) => setFrequencyUnit(id)}
        />
      </div>
      {editing && (
        <div className="flex gap-2 mt-4 max-w-sm justify-end">
          <Action.S onClick={setDefaultValues}>Cancel</Action.S>
          <Action.P
            performing={loading}
            disabled={loading || frequencyValue < 1 || frequencyValue > 99}
            type="submit"
            onClick={() =>
              updateStoreAutoCloseQuotes({
                variables: {
                  enabled,
                  frequencyValue,
                  frequencyUnit,
                  storeId,
                },
              })
            }
          >
            Save
          </Action.P>
        </div>
      )}
    </div>
  )
}
