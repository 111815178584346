import { OpenQuotesReportQuery, SortOrder, useOpenQuotesReportQuery } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import Money from '@/gf/modules/Money'
import keyBy from 'lodash/keyBy'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import BreakdownTable from './OpenQuotes/BreakdownTable'
import Chart from './OpenQuotes/Chart'
import { Context } from './OpenQuotes/Context'
import HistoryTable from './OpenQuotes/HistoryTable'
import TitleMetric from './OpenQuotes/TitleMetric'

export type StoreOrder = OpenQuotesReportQuery['storeOrders'][number]
export type Org = StoreOrder['customer']['organization']

export type UpdateArgs = {
  orgId?: string | null
  breakdownBy?: string | null
  breakdownCustomerSort?: { field: string; order: SortOrder } | null
  ordersSort?: { field: string; order: SortOrder } | null
  days?: string | null
}

export const total = (storeOrders: StoreOrder[]) =>
  storeOrders.map((so) => so.totals.total).reduce(Money.add, Money.fromDecimal(0, 'USD'))

export const formatMoneyInt = (value: number) =>
  Money.format(Money.fromInt(value, 'USD'), { maximumFractionDigits: 0 })

const useQueryParamsOpts = {
  days: StringParam,
  orgId: StringParam,
  'breakdown.by': StringParam,
  'breakdown.customer.sort': StringParam,
  'orders.sort': StringParam,
}

const OpenQuotes = () => {
  const [query, setQuery] = useQueryParams(useQueryParamsOpts)
  const {
    storeId,
    store: { autoCloseQuotes },
  } = useSession()
  const { data } = useOpenQuotesReportQuery({ variables: { storeId } })
  const { storeOrders } = data || {}

  const update = ({ orgId, breakdownCustomerSort, ordersSort, breakdownBy, days }: UpdateArgs) => {
    if (orgId !== undefined) setQuery({ orgId: orgId || undefined })
    if (days !== undefined) setQuery({ days: days || undefined })
    if (breakdownBy !== undefined) setQuery({ 'breakdown.by': breakdownBy || undefined })

    if (breakdownCustomerSort !== undefined)
      setQuery({
        'breakdown.customer.sort': breakdownCustomerSort
          ? `${breakdownCustomerSort.field}.${breakdownCustomerSort.order.toLowerCase()}`
          : undefined,
      })

    if (ordersSort !== undefined)
      setQuery({
        'orders.sort': ordersSort
          ? `${ordersSort.field}.${ordersSort.order.toLowerCase()}`
          : undefined,
      })
  }

  const breakdownBy = query['breakdown.by'] || null
  const breakdownCustomerSort = query['breakdown.customer.sort']?.split('.') || null
  const orgId = query.orgId || null
  const ordersSort = query['orders.sort']?.split('.') || null
  const days = query.days || null

  const orgById =
    storeOrders &&
    keyBy(
      storeOrders.map((so) => so.customer.organization),
      (o) => o.id
    )

  const autoCloseQuotesText = useMemo(() => {
    if (!autoCloseQuotes) {
      return 'By default, quotes that stay inactive for over six months are automatically closed'
    }

    if (autoCloseQuotes?.enabled === false) {
      return 'This feature is disabled'
    }

    return `Quotes that stay inactive for over ${autoCloseQuotes.frequencyValue} ${autoCloseQuotes.frequencyUnit} are automatically closed`
  }, [autoCloseQuotes])

  return (
    <Context.Provider
      value={{
        storeOrders,
        orgById,
        days,
        orgId,
        breakdownBy,
        breakdownCustomerSort,
        ordersSort,
        update,
      }}
    >
      <ChartBreakdownLayout
        titleMetric={<TitleMetric />}
        chart={<Chart />}
        breakdownTable={<BreakdownTable />}
        historyTable={<HistoryTable />}
      />

      <div className="p-6 bg-white border border-gray-300 rounded-lg shadow-sm text-gray-900">
        <h1 className="text-lg font-medium">Automatically Close Stale Quotes</h1>
        <br />
        <p className="text-sm leading-4">
          {autoCloseQuotesText}. You can change this at the{' '}
          <Link to="/settings/workspace#close-stale-quotes" className="text-blue-500 underline">
            Workspace Settings
          </Link>
          .
        </p>
        <br />
      </div>
    </Context.Provider>
  )
}

export default OpenQuotes
