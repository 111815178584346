import { Maybe } from '@/types'
import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { RequestForQuoteQuery } from '@/dealers/_gen/gql'

type RequestMachine = NonNullable<RequestForQuoteQuery['requestForQuote']>['machines'][number]

const Info = ({ label, value }) => (
  <div className="flex items-center gap-x-4">
    <div className="leading-6 flex-grow max-w-44">{label}</div>
    <div className="leading-6">{value}</div>
  </div>
)

const MachineInformation = ({
  reqMachine,
  className,
  textSize = 'sm',
}: {
  reqMachine: Maybe<RequestMachine>
  className?: string
  textSize?: 'sm' | 'base'
}) => (
  <div className={classNames('flex flex-col gap-y-2', className)}>
    <h4 className="text-lg font-medium">Machine</h4>
    {reqMachine ? (
      <div
        className={classNames(textSize === 'sm' && 'text-sm', textSize === 'base' && 'text-base')}
      >
        <div className="font-medium mb-2">
          {reqMachine.details.machine.year} {reqMachine.details.machine.make}{' '}
          {reqMachine.details.machine.model}
        </div>
        <Info label="Serial Number:" value={reqMachine.details.serialNumber} />
        {reqMachine.details.description && (
          <Info label="Description:" value={reqMachine.details.description} />
        )}
        {(reqMachine.details.engineMake || reqMachine.details.engineModel) && (
          <Info
            label="Engine Details:"
            value={`${reqMachine.details.engineMake || ''} ${reqMachine.details.engineModel || ''}`}
          />
        )}
        {reqMachine.details.engineSerialNumber && (
          <Info label="Engine Serial Number:" value={reqMachine.details.engineSerialNumber} />
        )}
        {reqMachine.machineDown && (
          <Info
            label="Status:"
            value={
              <span className="text-red-700 inline-flex items-center font-medium gap-x-2">
                <ExclamationIcon className="h-5 w-5 inline-block" />
                Machine down
              </span>
            }
          />
        )}
      </div>
    ) : (
      <p>N/A (stock order request)</p>
    )}
  </div>
)

export default MachineInformation
