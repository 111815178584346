import VendorRequestEmail from '@/gf/components/VendorRequestEmail'
import { RequestForQuote } from '@/types'
import { useParams } from 'react-router-dom'
import { usePrintRequestQuery } from '../_gen/gql'

const PrintRequest = () => {
  const { rfqId } = useParams() as {
    rfqId: string
  }

  const { data } = usePrintRequestQuery({
    variables: { id: rfqId },
    onCompleted: () => {
      setTimeout(() => {
        window.print()
        window.close()
      }, 500)
    },
  })

  const rfq = data?.requestForQuote ?? null

  if (!rfq) {
    return null
  }

  const customerName =
    rfq.user.displayName || (rfq.user?.organization ? rfq.user.organization.name : 'your customer')

  const rfqOrgMachine = rfq.machines[0]

  const machine = rfqOrgMachine
    ? { ...rfqOrgMachine.details, ...rfqOrgMachine.details.machine }
    : null

  return (
    <VendorRequestEmail.Wrapper>
      <VendorRequestEmail
        readonly
        showEmailCopy={false}
        customerName={customerName ?? null}
        customerPhoneNumber={rfq.user?.phoneNumber || rfq.phoneNumber}
        customerEmail={(rfq.user?.email || rfq.emailAddress) ?? null}
        organization={(rfq.user as unknown as RequestForQuote['user'])?.organization ?? null}
        // branch={(rfq?.branch as RequestForQuote['branch']) ?? null}
        branch={null}
        vendor={rfq.requestDetails?.vendor ?? null}
        machineDown={rfqOrgMachine?.machineDown ?? null}
        requestTimestamp={rfq.insertedAt}
        neededBy={rfq.neededBy}
        shippingAddress={rfq.shippingAddress ?? null}
        billingAddress={rfq.billingAddress}
        deliveryMethod={rfq.requestDetails?.deliveryMethod}
        accountNumber={rfq.requestDetails?.accountNumber ?? ''}
        machine={machine}
        partRequests={rfq.partRequests}
        additionalDetails={rfq.partsRequest || ''}
        imageUrls={rfq.images?.map((image) => image.url) ?? []}
        organizationLogoUrl={rfq.user?.organization?.logoUrl}
        customerNote={rfq.requestDetails?.customerNote ?? null}
        hideUnitNumber
      />
    </VendorRequestEmail.Wrapper>
  )
}

export default PrintRequest
