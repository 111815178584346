import { useState } from 'react'
import { RequestForQuoteDocument, useCloseQuoteMutation } from '@/dealers/_gen/gql'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'
import CloseModalButton from '@/gf/components/CloseModalButton'
import TextArea from '@/gf/components/next/forms/TextArea'
import Select from '@/gf/components/Select'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { Option } from '@/types'

const reasonOptions: Option[] = StoreOrderM.CANCEL_REASONS.map((reason) => ({
  id: reason.id,
  display: reason.title,
}))

const CancelOrderModal = ({
  open,
  onClose,
  storeOrderId,
  rfqId,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  rfqId: string
}) => {
  const [reason, setReason] = useState<string>('')
  const [details, setDetails] = useState('')
  const [_msgs, msgsMgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [closeQuoteMutation] = useCloseQuoteMutation({
    refetchQueries: [RequestForQuoteDocument],
  })

  const onSubmit = async () => {
    if (!reason) return

    spinner.on()

    try {
      await closeQuoteMutation({
        variables: {
          storeOrderId,
          rfqId,
          reason,
          details: reason === '5' ? details : '',
        },
      })

      msgsMgr.add('Order canceled!', 'positive')
      onClose()
    } catch (err) {
      console.error(err)
      msgsMgr.addUnknownError()
    } finally {
      spinner.off()
    }
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="relative p-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <div className="space-y-4">
          <h3 className="font-medium text-2xl">Cancel Order</h3>

          <Select
            currentId={reason}
            onChange={setReason}
            options={reasonOptions}
            placeholder="Please select reason for canceling..."
          />

          {reason === '5' && (
            <TextArea
              value={details}
              onChange={(e) => setDetails(e?.target.value)}
              placeholder="Reason for cancelation..."
              required
            />
          )}

          <div className="flex justify-end gap-x-4">
            <Action.S onClick={onClose}>Cancel</Action.S>
            <Action.P
              onClick={onSubmit}
              disabled={!reason || (reason === '5' && !details)}
              performing={spinnerLive}
            >
              Cancel Order
            </Action.P>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CancelOrderModal
