import {
  UpdateWorkspaceMutationVariables,
  useUpdateWorkspaceMutation,
  useWorkspaceSettingsQuery,
} from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import Box from '@/gf/components/Box'
import classNames from 'classnames'
import { WorkspaceSettingsContext } from './WorkspaceSettings/context'
import Network from './WorkspaceSettings/Network'
import ShippingRates from './WorkspaceSettings/ShippingRates'
import AutoCloseQuotes from './WorkspaceSettings/AutoCloseQuotes'

const H1 = ({ children, className, ...props }: JSX.IntrinsicElements['h1']) => (
  <h1
    className={classNames('text-gray-900 text-2xl font-medium leading-loose', className)}
    {...props}
  >
    {children}
  </h1>
)

export const H2 = ({ children, ...props }: JSX.IntrinsicElements['h2']) => (
  <div className="text-gray-500 text-base font-medium leading-normal" {...props}>
    {children}
  </div>
)

export const Section = (props: JSX.IntrinsicElements['div']) => (
  <div className="px-8 py-6 last:pb-0 flex flex-col gap-6" {...props} />
)

const WorkspaceSettings = () => {
  const {
    store: { id: storeId },
  } = useSession()

  const [updateWorkspaceMutation] = useUpdateWorkspaceMutation()

  const { data, loading, refetch } = useWorkspaceSettingsQuery({
    variables: { storeId },
    notifyOnNetworkStatusChange: true,
  })

  const { store, brands, categories } = data || {}

  const updateWorkspace = (variables: Partial<UpdateWorkspaceMutationVariables>) => {
    if (!store) return

    updateWorkspaceMutation({
      variables: {
        storeId,
        rfqStates: store.rfqStates,
        brandIds: store.brands.map((b) => b.id),
        categoryIds: store.categories.map((c) => c.id),
        ...variables,
      },
    }).finally(() => refetch())
  }

  if (store === null) return null

  return (
    <WorkspaceSettingsContext.Provider
      value={{ storeId, store, brands, categories, loading, refetch, updateWorkspace }}
    >
      <Box className="py-8">
        <H1 className="px-8">Workspace Settings</H1>

        <div className="divide-y">
          <ShippingRates />
          <Network />
          <AutoCloseQuotes />
        </div>
      </Box>
    </WorkspaceSettingsContext.Provider>
  )
}

export default WorkspaceSettings
